/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
import React, { lazy, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import { Layout } from "../_metronic/layout";
import '../css/custom.css';
import * as auth from './modules/Auth/_redux/authRedux'
import * as crud from './modules/Application/_redux/admin/adminCrud'

import BasePage from "./BasePage";
// import { MobilePage } from "./MobilePage";
import { Logout, AuthPage, Authenticatemodule, LoadingModule, Invite, Checkmembership } from "./modules/Auth";
import * as config from './config';
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import helper_funcs from '../js/helper_funs'
import { adminSlice } from './modules/Application/_redux/admin/adminSlice'
import { rolesSlice } from './modules/Application/_redux/roles/rolesSlice'
import { usersSlice } from './modules/Application/_redux/users/usersSlice'
import { fetchUserProfile } from './modules/Application/_redux/admin/adminActions'

import {
    isMobile
} from "react-device-detect";

console.log("isMobile",isMobile,isMobile.toString())

const { actions } = adminSlice;
const rolesAction = rolesSlice.actions;
const usersAction = usersSlice.actions;
const ApplicationPages = lazy(() =>
    import("./applicationPage")
);

export function Routes() {

    const history = useHistory();
    const dispatch = useDispatch();
    const { authToken, user } = useSelector(state => state.auth);
    const admin = useSelector(state => state.admin);


    const get_current_business_name_from_business_list = (business_list, current_business_id) => {
        let current_business_name = 'No business';
        if (business_list.length) {
            for (let i = 0; i < business_list.length; i++) {
                if (business_list[i].id === current_business_id) {
                    current_business_name = business_list[i].name;
                    break;
                }
            }
        }
        return current_business_name;
    }

    const do_things = async () => {
        //when things are going to load, disable clicks everywhere and at the end we will enable clicks

        let current_route = helper_funcs.get_current_route(location)
        document.body.style.pointerEvents = 'none';
        const urlParams = new URLSearchParams(window.location.search)
        let code = '';
        if (urlParams.get('code')) {
            code = urlParams.get('code');
        }

        //if there is invitation code we need to sent it to API
        var invitation_code = '';
        if (urlParams.get('state')) {
            invitation_code = urlParams.get('state');
        }
        //lets check if token is valid otherwise check if there is code sent, then we will get access token and jwt token eventually

        if (authToken) {
            if (location.pathname !== '/auth/loading') {
                history.push('/auth/loading')
            }
            
            //if there is no code sent, then there should be jwt in local storage
            let setting_response = await crud.get_user_module_setting({ token: authToken });
            await dispatch(actions.setState({ setting: setting_response.data.setting }))

            let user_info = await crud.get_user_info_from_jwt_token(authToken);
            if (user_info) {

                // await dispatch(auth.actions.fulfillUser(user_info))
                let user_complete_name = '';
                if (user_info.status) {
                    user_complete_name = user_info.user_complete_name;
                }

                let current_business_id = user_info.business_id;
                if (current_business_id !== 0) {
                    //we need to store current business details in our state
                    // let business_details_response = await crud.get_business_details({ token: authToken });
                    // if (business_details_response.status === 1) {
                    //     await dispatch(actions.setState({ business_details: business_details_response.data }))
                    // }
                    //get business list now
                    let business_list_response = await crud.get_business_list({ token: authToken });

                    let business_list = [];
                    if (business_list_response.status) {
                        business_list = business_list_response.data;
                    }

                    await dispatch(actions.setState({ business_list: business_list }))
                    let current_business_name = await get_current_business_name_from_business_list(business_list, current_business_id);

                    let business_data = {}
                    business_data['id'] = current_business_id
                    business_data['name'] = current_business_name
                    await dispatch(actions.setState({ business_details: business_data }))

                    let is_admin = user_info.is_admin;
                    let auth_data = {
                        user_complete_name: user_complete_name,
                        business_id: current_business_id,
                        business_name: current_business_name,
                        profile: user_info.profile,
                        role_level: user_info.role_level,
                        is_admin: is_admin,
                        user_status: user_info.user_status
                    };
                    await dispatch(actions.setState({ auth_data: auth_data }))


                    // this.redirect_for_index_page(current_business_id);
                    //otherwise let user go to their required route
                }
                else {

                    //user does not have any business, there are some pages, which should still be available for user, if user is on one of those pages, then it is ok, otherwise move user to ask for business
                    //this check says, if it is a page, which requires a business and there is no business selected, then move user to ask for business page
                    if (config.ROUTES_ALLOWED_WITHOUT_BUSINESS.indexOf(current_route) === -1) {
                        history.push('/application/askforbusiness/0')
                        document.body.style.pointerEvents = '';
                        return false
                    }
                }
            }
            document.body.style.pointerEvents = '';
        }
        else if (code !== '') {
            if (location.pathname !== '/auth/loading') {

                if (invitation_code !== '') {
                    history.push('/auth/loading?code=' + code + "&state=" + invitation_code)
                }
                else {
                    history.push('/auth/loading?code=' + code)
                }

            }
            let data = {};
            data['code'] = code;
            let access_token_response = await crud.getToken(data)
            //if we do not get access_token from above API then call logout
            if (!access_token_response.hasOwnProperty('access_token')) {
                document.body.style.pointerEvents = '';
                history.push("/logout");
            }
            //let it go further
            data['access_token'] = access_token_response.access_token;
            if (invitation_code !== '') {
                data['invitation_code'] = invitation_code;
            }
            let response = await crud.create_and_save_jwt_token(data)
            if (response.status) {
                await dispatch(actions.setState({ setting: response.setting }))
                //if this user's profile is created just now, then we need to ask them referral code
                let ask_for_referral_code = response.profile_created_now

                await dispatch(auth.actions.updateToken(response.token))
                //this token has logged in user information so lets get it
                let user_info = await crud.get_user_info_from_jwt_token(response.token);

                if (response.role_level === 0) {
                    user_info.role_name = response.role_name
                }
                else {
                    user_info.role_name = response.role_name
                    user_info.profile_name = response.profile_name
                    user_info.department_name = response.department_name
                }


                await dispatch(auth.actions.fulfillUser(user_info))
                // await dispatch(fetchUserProfile({ token: response.token, user_id: user_info.user_id }))
                let user_complete_name = user_info.user_complete_name;
                let current_business_id = user_info.business_id;
                if (current_business_id !== 0) {
                    //we need to store current business details in our state
                    // let business_details_response = await crud.get_business_details({token:response.token});

                    // if(business_details_response.status===1){
                    //     await dispatch(actions.setState({business_details:business_details_response.data}))
                    // }

                    let current_business_name = 'No business';
                    if (response.business_list) {
                        let business_list = JSON.parse(response.business_list);
                        await dispatch(actions.setState({ business_list: business_list }))
                        current_business_name = await get_current_business_name_from_business_list(business_list, current_business_id);
                        let business_data = {}
                        business_data['id'] = current_business_id
                        business_data['name'] = current_business_name
                        await dispatch(actions.setState({ business_details: business_data }))
                    }

                    let is_admin = user_info.is_admin;
                    let auth_data = {}
                    auth_data['user_complete_name'] = user_complete_name
                    auth_data['business_id'] = current_business_id
                    auth_data['business_name'] = current_business_name
                    auth_data['profile'] = user_info.profile
                    auth_data['role_level'] = user_info.role_level
                    auth_data['is_admin'] = is_admin
                    auth_data['user_status'] = user_info.user_status
                    await dispatch(actions.setState({ auth_data: auth_data }))
                    document.body.style.pointerEvents = '';

                    // let deps_response = await crud.get_business_modules({ token: response.token });

                    // if (deps_response.status) {
                    //     let modules = deps_response.data;
                    //     await dispatch(actions.setState({business_modules:modules}))
                    // }

                    // let roles_response = await crud.get_business_roles({ token: response.token });
                    // if (roles_response.status) {
                    //     let roles = roles_response.data;
                    //     await dispatch(rolesAction.setState({roles_list: roles}))
                    // }

                    // let users_response = await crud.users_list({ token: response.token });
                    // if (users_response.status) {
                    //     let users = users_response.data;
                    //     await dispatch(usersAction.setState({user_list: users}))
                    // }

                    if (current_business_id === 0) {
                        history.push('/application/askforbusiness/' + ask_for_referral_code)
                        document.body.style.pointerEvents = '';
                        return false;
                    }
                    else {
                        //only owner and ceo can see dashboard page
                        if (user_info.role_level > 1) {
                            history.push("/application/modules/" + ask_for_referral_code)
                        }
                        else {
                            history.push("/application/dashboard/" + ask_for_referral_code)
                        }
                        document.body.style.pointerEvents = '';
                        return false;
                        // history.push(`/application/editbusiness?id=${current_business_id}`)
                    }
                    //otherwise let user go to their required route
                }
                else {
                    history.push('/application/askforbusiness/' + ask_for_referral_code)
                    document.body.style.pointerEvents = '';
                    return false;
                }
            }
            else {
                document.body.style.pointerEvents = '';
                history.push("/logout");
                return false;
            }
        }
        else if (current_route !== undefined) {
            document.body.style.pointerEvents = '';
            history.push("/logout");
            return false;
        }
        document.body.style.pointerEvents = '';

        if (location.pathname.indexOf("loading") > -1) {
            //only owner and ceo can see dashboard page
            if (user.role_level > 1) {
                history.push("/application/modules")
            }
            else {
                history.push("/application/dashboard")
            }
        }
        else {
            history.push(location.pathname)
        }

    }

    useEffect(() => {
        if (current_route !== 'logout' && current_route !== "authenticatemodule" && current_route !== 'restricted_access')
            do_things();
    }, [])



    const { isAuthorized } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.user != null,
        }),
        shallowEqual
    );
    let location = useLocation();
    let current_route = helper_funcs.get_current_route(location)


    const path_name = location.pathname
    return (

            <Switch>

                {
                    !isAuthorized && current_route === 'index' && path_name === '/admin/index' ? (
                        /*Render index page when user at index and not authorized.*/
                        <Route><Layout><BasePage /></Layout></Route>
                    ) : ''
                }
                {!isAuthorized && current_route === 'index' ? (
                    /*Render index page when user at index and not authorized.*/
                    <Route>
                        <AuthPage />
                    </Route>
                ) : ''
                }

                {!isAuthorized && (current_route === 'login') ?
                    <Route>
                        <AuthPage />
                    </Route>
                    : ''
                }

                <Route path="/error" component={ErrorsPage} />
                <Route path="/logout" component={Logout} />
                <Route path="/auth/authenticatemodule" component={Authenticatemodule} />
                <Route path="/auth/loading" component={LoadingModule} />
                <Route path="/auth/invite/:invitelink" component={Invite} />
                <Route path="/auth/checkmembership/:invitation_code" component={Checkmembership} />

                {
                    isAuthorized && current_route !== 'error' && current_route !== 'logout' ?
                        <Layout>
                            <ApplicationPages user={user} history={history} current_route={current_route} location={location} />
                        </Layout>
                        : ''
                }


            </Switch>
    );
}
